import { makeDropdown, pad } from "../../utils";

import currencies from "../../../../resources/data/currencies";
import store from "../../store";

export const addClaim = ({ form, store, db }) => {
    form.setConfig({
        "title": "Add Claim",
        "type": "push",
        "location": "Claims/Claims",
        "fields": [{
            "label": "Description",
            "name": "Description",
            "type": "text",
            "divClass": "md:col-span-3"
        }, {
            "label": "Out of Office Expense Form",
            "type": "sectionHeader",
            "divClass": "md:col-span-3"
        }, {
            "label": "Reason",
            "name": "Reason",
            "type": "dropdown",
            "dataLocation": "Claims/Settings/ClaimReasons",
            "labelKey": "Name",
            "idKey": "ID"
        }, {
            "label": "Commercial",
            "name": "Commercial",
            "type": "dropdown",
            "dataLocation": "Database/Refs/Commercials",
            "labelKey": "Number",
            "idKey": "ID"
        }, {
            "label": "Start Date",
            "type": "date",
            "name": "Date[Start]"
        }, {
            "label": "End Date",
            "type": "date",
            "name": "Date[End]"
        }],
        preSubmitHook({ data, selectedDropdowns }) {
            return {
                "Authorised": "Not Authorised",
                "Completed": "Not Authorised",
                "Number": `Claim-${pad(store.state.counts.claims.count, 4)}`,
                "Date": {
                    "Start": data.Date.Start,
                    "End": data.Date.End,
                    "Authorised": "Not Authorised",
                    "Completed": "Not Completed",
                    "Created": (new Date()).toISOString()
                },
                "Returned": false,
                "User": {
                    "Xero": store.state.user.Basic.xero,
                    "Name": store.state.user.Basic.name,
                    "UID": store.getters.userID
                },
                "Stage": "Unsubmitted",
                "Type": "Out of Office Expense",
                "TypeID": "02",
                "Commercial": {
                    "Number": selectedDropdowns.Commercial.label,
                    "ID": selectedDropdowns.Commercial.id
                }
            };
        },
        async postSubmitHook({  }) {
            await db.utils.upCount("counts/claims/count");
        }
    });
    form.getDropdownData();
    form.toggle();
};


const outOfficeFields = [{
    "label": "Category",
    "name": "Type",
    "type": "dropdown",
    "dataLocation": "Claims/Settings/ItemTypes",
    "labelKey": "Name",
    "idKey": "ID"
}, {
    "label": "Number of Claimants",
    "name": "People",
    "type": "number"
}, {
    "label": "Car Mileage Calculator",
    "type": "sectionHeader",
    "divClass": "md:col-span-3",
    "break": true,
    show({ liveInputData }) {
        return liveInputData && liveInputData.Type === "---10";
    }
}, {
    "label": "Rate",
    "type": "dropdown",
    "name": "RATE",
    "dropdownData": [{
        "label": "Less than 10,000 miles driven this year",
        "id": 45
    },
    {
        "label": "More than 10,000 miles driven this year",
        "id": 25
    }],
    show({ liveInputData }) {
        return liveInputData && liveInputData.Type === "---10";
    }
}, {
    "label": "Distance",
    "name": "DIST",
    "break": true,
    "type": "number",
    show({ liveInputData }) {
        return liveInputData && liveInputData.Type === "---10";
    }
}, {
    "label": "Payment",
    "type": "sectionHeader",
    "divClass": "md:col-span-3",
    "break": true
}, {
    "label": "Payment Method",
    "name": "PaymentType",
    "type": "dropdown",
    "dataLocation": "Claims/Settings/PaymentTypes",
    "labelKey": "Name",
    "idKey": "Name"
}, {
    "label": "Currency",
    "name": "Currency[Name]",
    "type": "dropdown",
    "dropdownData": Object.values(currencies).map(i => {
        return {
            "id": i.code,
            "label": i.code,
            "meta": i
        };
    })
}, {
    "label": "Cost",
    "name": "Cost",
    "type": "number",
    "step": "0.01",
    show({ liveInputData }) {
        return liveInputData && liveInputData.Type !== "---10";
    }
}, {
    "label": "Cost",
    "type": "value",
    "name": "Cost",
    value({ liveInputData }) {
        if (!liveInputData.RATE || !liveInputData.DIST) return "0.00";
        else return ((parseFloat(liveInputData.RATE) * parseFloat(liveInputData.DIST)) / 100).toFixed(2);
    },
    show({ liveInputData }) {
        return liveInputData && liveInputData.Type === "---10";
    }
}];

export const addClaimItem = ({ form, pageItem, attachedItems }) => {
    form.setValues({
        "People": 1
    });
    form.setConfig({
        "title": `Add Item (${pad(attachedItems?.Items?.items.length + 1 || 1, 3)})`,
        "type": "push",
        "location": "Claims/Items",
        "fields": outOfficeFields,
        preSubmitHook({ selectedDropdowns }) {
            return {
                "Currency": {
                    "Symbol": selectedDropdowns["Currency[Name]"].meta.symbol
                },
                "Number": pad(attachedItems?.Items?.items.length + 1 || 1, 3),
                "Parent": {
                    "ID": pageItem.ID,
                    "Type": pageItem.Type
                },
                "People": 1,
                "Date": {
                    "Created": (new Date()).toISOString()
                }
            };
        },
        async postSubmitHook({ db, data, typeRef }) {
            await db.set(`Claims/Claims/${pageItem.ID}/Items/${typeRef.key}`, data.Cost);
            const items = Object.values(await db.once(`Claims/Claims/${pageItem.ID}/Items`));
            const Total = items.reduce((acc, i) => {
                return Math.round((acc + parseFloat(i)) * 100) / 100;
            }, 0);
            await db.set(`Claims/Claims/${pageItem.ID}/TotalCost`, Total);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editClaimItem = ({ form, pageItem, rowItem, attachedItems }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Edit Claim Item",
        "type": "update",
        "location": `Claims/Items/${rowItem.ID}`,
        "fields": outOfficeFields,
        preSubmitHook({ selectedDropdowns }) {
            return {
                "Currency": {
                    "Symbol": selectedDropdowns["Currency[Name]"].meta.symbol
                }
            };
        },
        async postSubmitHook({ db, data, typeRef }) {
            await db.set(`Claims/Claims/${pageItem.ID}/Items/${typeRef.key}`, data.Cost);
            const items = Object.values(await db.once(`Claims/Claims/${pageItem.ID}/Items`));
            const Total = items.reduce((acc, i) => {
                return Math.round((acc + parseFloat(i)) * 100) / 100;
            }, 0);
            await db.set(`Claims/Claims/${pageItem.ID}/TotalCost`, Total);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const removeClaimItem = ({ form, pageItem, rowItem, attachedItems }) => {
    form.setConfig({
        "title": "Remove Claim Item",
        "type": "delete",
        "location": `Claims/Items/${rowItem.ID}`,
        "fields": [],
        async postSubmitHook({ db, data, typeRef }) {
            await db.remove(`Claims/Claims/${pageItem.ID}/Items/${rowItem.ID}`);
            const items = Object.values(await db.once(`Claims/Claims/${pageItem.ID}/Items`));
            const Total = items.reduce((acc, i) => {
                return Math.round((acc + parseFloat(i)) * 100) / 100;
            }, 0);
            await db.set(`Claims/Claims/${pageItem.ID}/TotalCost`, Total);
        }
    });
    form.toggle();
};

export const addReceipt = ({ form, pageItem }) => {
};

export const updateClaim = ({ form, store }) => {
};

export const submitClaim = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Submit Claim",
        "location": `Claims/Claims/${pageItem.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                "Stage": "Waiting for Authorisation",
                "Date/Submitted": (new Date()).toISOString()
            };
        }
    });
    form.toggle();
};

export const approveClaim = ({ form, pageItem, store }) => {
    form.setConfig({
        "title": "Authorise Claim",
        "location": `Claims/Claims/${pageItem.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                "Authorised": true,
                "AuthorisedBy": store.getters.userName,
                "Stage": "Waiting for Payment",
                "Date/Authorised": (new Date()).toISOString()
            };
        }
    });
    form.toggle();
};

export const cancelClaim = ({ form, pageItem }) => {
};

export const completeClaim = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Complete Claim",
        "location": `Claims/Claims/${pageItem.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                "Completed": store.getters.userName,
                "Stage": "Completed",
                "Date/Completed": (new Date()).toISOString()
            };
        }
    });
    form.toggle();
};

export const uploadReceipt = ({ form, pageItem, rowItem, db, attachedItems }) => {

    // chceck to see if they are upload directly to a claim item or from the receipts section
    const fields = !!rowItem ? [{
        "label": "Receipt",
        "type": "file",
        "name": "File"
    }] : [{
        "label": "Receipt",
        "type": "file",
        "name": "File"
    }, {
        "label": "Select Item",
        "name": "Item",
        "type": "dropdown",
        "multiple": false,
        "required": true,
        "dropdownData": makeDropdown(store, attachedItems.Items.items, "Number", "ID")
    }];

    form.setConfig({
        "title": "Upload Receipt",
        "location": `Claims/Receipts`,
        "type": "push",
        "fields": fields,
        async preSubmitHook({ files, data }) {
            try {

                await db.upload(`receipts/${pageItem.ID}`, files[0][0]);
                return {
                    "Claim": pageItem.ID,
                    "File": `receipts/${pageItem.ID}/${files[0][0].name}`,
                    "Item": data.Item ? data.Item : rowItem.ID
                };
            } catch (err) {
                console.error(err);
            }
        },
        postSubmitHook({ typeRef }) {
            db.set(`Claims/Claims/${pageItem.ID}/Receipts/${typeRef.key}`, true);
            db.set(`Claims/Items/${rowItem.ID}/Receipt/`, typeRef.key);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const downloadReceipt = async ({ pageItem, rowItem, db }) => {
    const url = await db.download(rowItem.File);
    window.open(url, "__blank");
};

export const downloadItemsReceipt = async ({ pageItem, rowItem, db }) => {
    const url = await db.download(`receipts/${pageItem.ID}/${rowItem.Receipt}`);
    window.open(url, "__blank");
};

export const rejectClaim = async ({ pageItem, form}) => {
    form.setConfig({
        "title": "Reject Claim",
        "location": `Claims/Claims/${pageItem.ID}`,
        "type": "update",
        "fields": [],
        preSubmitHook() {
            return {
                "Stage": "Returned",
            };
        }
    });
    form.toggle();
};
