<template>
  <ag-grid-vue ref="table" style="width: 100%; height: 100%" class="ag-theme-material" :rowData="items" :pagination="true"
    :gridOptions="tableOptions" :quickFilter="quickFilter"></ag-grid-vue>
</template>
<script setup="props">

import { AgGridVue } from "ag-grid-vue3";
import { ref, defineProps, computed, watch, defineEmits, onBeforeUnmount } from "vue";
import { BooleanValue, BooleanFilter, CVEValue } from ".";
// event emiters
const emit = defineEmits(["itemSelected", "optionsChanged"]);

// refernce for the table
const table = ref(null);
const tableApi = ref({});
const gridApi = ref({});
const columnApi = ref({});

// Setup prop
const props = defineProps({
  items: Array,
  quickFilter: String,
  paginationPageSize: Number,
  tableSettings: Object,
  paginationAutoPageSize: Boolean
});

const tableSettingsWithColumnStateRemoved = computed(() => {
  let tableSettings = { ...props.tableSettings };
  delete tableSettings.columnState;
  return tableSettings;
});

const items = computed(() => {
  console.log(props.items);
  
  
  return props.items;
});

const tableOptions = ref({
  onRowClicked(row) {
    emit("itemSelected", row);
  },
  onGridReady(params) {
    tableApi.value = params.api;
    columnApi.value = params.api;
    gridApi.value = params?.api?.filterManager?.gridOptionsService?.api || params?.api?.filterManager?.gos?.api;
    setTableSettings();
  },
  getRowStyle(params) {
    if (params.node.rowIndex % 2 === 1) {
      return { background: "#eee" };
    }
  },
  onFilterChanged() {
    
    changeMade();
  },
  onSortChanged() {
    changeMade();
  },
  onColumnVisible() {
    changeMade();
  },
  onColumnPinned() {
    changeMade();
  },
  onColumnResized() {
    changeMade();
  },
  onColumnMoved() {
    changeMade();
  },
  onColumnValueChanged() {
    changeMade();
  },
  onFirstDataRendered() {
    setTableSettings();
  },
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    cellDataType: false,
    minWidth: 100,
    suppressFieldDotNotation: false,
    useValueFormatterForExport: true,
    useValueParserForImport: true,
  },
  rowHeight: 36,
  paginationPageSize: props.paginationPageSize || 15,
  paginationAutoPageSize: props.paginationAutoPageSize || false,
  suppressFieldDotNotation: false,
  columnDefs: [...props?.tableSettings?.columnState || []],
  columnTypes: {
    date: {
      filter: "agDateColumnFilter",
      useValueFormatterForExport: true,
      // cellRenderer: DateValue,
      valueFormatter(params) {
        function isDate(s) {
          if (isNaN(s) && !isNaN(Date.parse(s))) return true;
          else return false;
        }

        if (isDate(params.value) || params.value instanceof Date) {
          return new Date(params.value).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        } else {
          return params.value;
        }
      },
      filterParams: {
        comparator(a, b) {
          function isValidDate(date) {
            return (
              date &&
              Object.prototype.toString.call(date) === "[object Date]" &&
              !isNaN(date)
            );
          }
          let ta = isValidDate(a) ? a.getTime() : 0;
          let tb = isValidDate(new Date(b)) ? new Date(b).getTime() : 0;

          if (ta === 0 || tb === 0) return 0;
          return ta === tb ? 0 : ta < tb ? 1 : -1;
        },
      },
    },
    bool: {
      cellRenderer: BooleanValue,
      filterFramework: { ...BooleanFilter },
      filter: { ...BooleanFilter },
    },
    html: {
      valueFormatter(params) {
        return params.value.replace(/<[^>]*>/g, "").replace(/&[^;\s]+;/g, ' ').replace(/\s+/g, ' ').trim();
      },
    },
    currency: {
      valueFormatter(params) {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "gbp",
        });
        return formatter.format(parseFloat(params.value));
      },
    },
    cve: {
      cellRenderer: CVEValue,
    },
    number: {
      comparator(num1, num2) {
        return parseFloat(num2) - parseFloat(num1);
      },
    }
  },
  ...tableSettingsWithColumnStateRemoved.value,
});


const setTableSettings = () => {
  
  if (props?.tableSettings?.columnState?.length > 0 && columnApi.value) {
    // columnApi.value.applyColumnState({
    //   state: props.tableSettings?.columnState,
    // });
  }
  if (props?.tableSettings?.filter && gridApi.value) {
    gridApi.value.setFilterModel(props?.tableSettings?.filter);
  }
};

watch(
  () => props.quickFilter,
  (newValue) => {
    if (tableApi.value.setQuickFilter) {
      tableApi.value.setQuickFilter(newValue);
    }
  }
);


const destroy = () => {
  try {

    tableApi.value = null;
    columnApi.value = null;
    gridApi.value = null;
    tableApi.value.destroy();
  } catch (error) {
    
  }
}

const download = () => {
  
  props.tableSettings.exportParams ? tableApi.value.exportDataAsCsv(props.tableSettings.exportParams)  : tableApi.value.exportDataAsCsv();
};

var to;
const changeMade = () => {
  clearTimeout(to);

  to = setTimeout(() => {
    
    
    emit("optionsChanged");
    // setTimeout(setTableSettings, 100);
  }, 1000);
};

onBeforeUnmount(() => {
  destroy();
});

defineExpose({
  download,
  tableApi,
  columnApi,
  setTableSettings
});
</script>
