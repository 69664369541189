<template>
  <Shell :label="label" :tooltip="tooltip" class="shrink">
    <template v-slot:label-end>
      <p v-if="multiple" type="button" class="text-sm text-gray hover:text-primary cursor-pointer"
        @click="selectAllOptions">Select All</p>
    </template>
    <template v-slot:comp>
      <treeselect ref="inputElement" class="
          border-b-2 border-gray
          focus:border-primary focus:border-b-2
          w-full
        " style="background: rgba(0, 0, 0, 0); outline: none" :options="_options" v-bind="$attrs"
        v-model="$attrs.value" :multiple="multiple" :valueFormat="valueFormat || 'id'" :async="search || false"
        :name="$attrs.value === '__other__' ? undefined : $attrs.name" :load-options="search ? searcher : null"
        searchPromptText="Type at least 4 characters to search..." />
    </template>
  </Shell>
  <Input class="grow" label="Other" :name="$attrs.name" v-if="$attrs.value === '__other__'" />
</template>
<script setup="props">
import { defineProps, defineComponent, computed, ref } from "vue";
import Shell from "./Shell.vue";
import Input from "./Input.vue";
// disable attributte inheritence
defineComponent({
  inheritAttrs: false
});

let emits = defineEmits(["selectAll"])

// const attrs = useAttrs();
// define the component props
let props = defineProps({
  label: String,
  options: Array,
  multiple: Boolean,
  valueFormat: String,
  search: Boolean,
  hasOther: Boolean,
  showSelectAll: Boolean,
  tooltip: String
});

var delayTimer;

function* getResults(array, searchQuery, maxSize = 25) {
  console.log(array, searchQuery, maxSize);
  
  if (!array) return;
  if (!maxSize || maxSize > array.length) {
    maxSize = array.length;
  }

  let count = 0;
  let i = 0;
  while (count < maxSize && i < array.length) {

    if (array[i].label?.toString().toLowerCase().includes(searchQuery?.toLowerCase())) {
      console.log(array[i]);
      
      yield array[i];
      count++;
    }
    i++;
  }
};

function searcher({ action, searchQuery, callback }) {
  if (action === "ASYNC_SEARCH" && searchQuery && searchQuery.length >= 3) {
    if (delayTimer) clearTimeout(delayTimer);

    delayTimer = setTimeout(
      callback(null, [...getResults(props.options, searchQuery)]),
      500
    );
  } else if (action === "ASYNC_SEARCH") {
    callback(null, []);
  }
}

const _options = computed(() => {
  return props.hasOther ? [...props.options, {
    "id": "__other__",
    "label": "Other"
  }] : props.options;
});
const inputElement = ref(null);

const selectAllOptions = () => {
  let selectedIds = inputElement.value.selectedNodes.map(node => node?.id);
  inputElement.value.options.forEach(option => {
    let node = inputElement.value.getNode(option?.id);

    if (!selectedIds.includes(option?.id)) inputElement.value.select(node);

  });
};

defineExpose({ inputElement });
// get the attrb on the component and bind them to the input element

</script>
