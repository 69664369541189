import {
    addTester,
    approveSignatoryApplication,
    editTester,
    makeActive,
    makeInactive,
    updateApprovedFunctions,
    updateEmployee
} from "./actions.js";
import {
    files,
    files2,
    formfour,
    limitations,
    notes,
    panels,
    questions,
    questions2,
    records,
    testersFiles
} from "./internalAttachables";

import ItemListView from "../../../components/ItemListView/ItemListView.vue";
import ItemView from "../../../components/ItemView/ItemView.vue";
import SignatoryMatrix from "./SignatoryMatrix.vue";
import { db } from "../../../db.js";
import settings from "./Settings/routes.js"
import store from "../../../store.js";

// Function to generate the HTML span elements based on the input parameters
const span = (bool, totalPoints, threshold, string, item) => {
    // Check if the item is exempt from points requirements
    const exempt = item.PointsExempt || false;

    // Create the exempt string based on the exempt flag
    const exemptString = exempt || totalPoints - threshold >= 0 ? '' : `<span class="font-medium text-orange">&nbsp; - Point threshold of ${threshold} not met - Additional ${(totalPoints - threshold) * -1} points needed</span>`;

    return bool ?
        `<span class="a font-bold text-green">${string}${exemptString}</span>` :
        `<span class="b font-bold text-gray-300">${string}</span>`;
};

export default [{
    "path": "/Matrices/SignatoryMatrix/Signatories",
    "component": SignatoryMatrix,
    "name": "Approved Signatory Matrix",
    "meta": {
        "storeData": [
            "Matrices/SignatoryMatrix/InterviewQuestions",
            "Matrices/SignatoryMatrix/Panels",
            "Matrices/SignatoryMatrix/ApprovedFunctions"
        ],
        "name": "Current Signatory Matrix",
        "app": "Matrices",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 3,
        "icon": "book",
        "security": "Matrices.Read",
        "sideNavSection": "Signatory Matrix",
        "latest": false
    },
    lock(user) {
        return [user?.Security?.Matrices?.Reviewer !== true, "Cannot view this area"]
    }
}, {
    "path": "/Matrices/SignatoryMatrix/UnapprovedSignatoriesChanges",
    "component": SignatoryMatrix,
    "name": "Unapproved Signatory Matrix (Next Issue)",
    "meta": {
        "storeData": [
            "Matrices/SignatoryMatrix/InterviewQuestions",
            "Matrices/SignatoryMatrix/Panels",
            "Matrices/SignatoryMatrix/ApprovedFunctions"
        ],
        "name": "Unapproved Changes (Next Issue)",
        "app": "Matrices",
        "sideNav": true,
        "appSwitcher": false,
        "appSwitcherPosition": 3,
        "icon": "book",
        "security": "Matrices.Reviewer",
        "sideNavSection": "Signatory Matrix",
        "latest": true,
        lock(user) {
            return [user?.Security?.Matrices?.Write !== true, "Cannot view this area"]
        }
    },
    lock(user) {
        return [user?.Security?.Matrices?.Write !== true, "Cannot view this area"]
    }
}, {
    "path": "/Matrices/SignatoryMatrix/Form4Holders",
    "component": { ...ItemListView },
    "name": "Post Holders",
    "meta": {
        "storeData": ["Matrices/SignatoryMatrix/InterviewQuestions", "Matrices/SignatoryMatrix/Panels", "Matrices/SignatoryMatrix/ApprovedFunctions"],
        "app": "Matrices",
        "sideNav": true,
        "icon": "book",
        "name": "Post Holders",
        "sideNavSection": "Signatory Matrix",
        "tableSettings": "Fourholders",
        "selectionRoute": "Matrices/SignatoryMatrix/Signatories/{ID}",
        "dataPath": "Matrices/SignatoryMatrix/Signatories",
        filter(item) {
            return Object.keys(item.ApprovedFunctions || {}).some(key => {
                return Object.values(store.state["Matrices/SignatoryMatrix/ApprovedFunctions"]).filter(i => i.FormFourFunction).map(i => i.ID).includes(key);
            });
        },
        lock(user) {
            return [user?.Security?.Matrices?.Reviewer !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Matrices/SignatoryMatrix/NewSignatories",
    "component": { ...ItemListView },
    "name": "Signatory Applications",
    "meta": {
        "storeData": [
            "Matrices/SignatoryMatrix/InterviewQuestions",
            "Matrices/SignatoryMatrix/Panels",
            "Matrices/SignatoryMatrix/ApprovedFunctions",
            "Matrices/SignatoryMatrix/InterviewQuestions",
            "Matrices/SignatoryMatrix/Helper"
        ],
        "app": "Matrices",
        "sideNav": true,
        "appSwitcher": false,
        "appSwitcherPosition": 3,
        "name": "Signatory Applications",
        "sideNavSection": "Signatory Matrix",
        "dataPath": "OpenForms/Signatories/",
        "tableSettings": "SignatoryApplications",
        "selectionRoute": "Matrices/SignatoryMatrix/NewSignatories/{ID}",
        map(item) {
            return {
                ...item,
                "Role": store.state["Matrices/SignatoryMatrix/ApprovedFunctions"][item?.Role]?.Name
            }
        },
        filter(item) {
            return item.Completed;
        },
        lock(user) {
            return [user?.Security?.Matrices?.HeadOfDesign !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Matrices/SignatoryMatrix/Signatories/:id/:showLatest?",
    "component": ItemView,
    "name": "Signatory",
    "meta": {
        "app": "Matrices",
        "name": "Signatory",
        "dataPath": "Matrices/SignatoryMatrix/Signatories",
        "storeData": ["Matrices/SignatoryMatrix/Signatories",

            "Matrices/SignatoryMatrix/InterviewQuestions",
            "Matrices/SignatoryMatrix/Panels",
            "Matrices/SignatoryMatrix/ApprovedFunctions",
            "Matrices/SignatoryMatrix/Helper"
        ],
        "dataPathOverride": "/Matrices/SignatoryMatrix/Signatories/:id",
        itemOverride(item, { store, router }) {
            store.commit("save", { "sigMatrixViewingNew": router.currentRoute.value.params.showLatest === "true" });
            let x = router?.currentRoute?.value?.params?.showLatest === "true" ? item : store?.state?.currentSignatoryMatrixRevision?.Signatories[item?.ID];
            return x;
        },
        async onBeforeMount() {
            let id = await db.watch(
                "Matrices/SignatoryMatrix/Revisions/RevisionID",
                store
            );
            await db.watch(
                `Matrices/SignatoryMatrix/Revisions/Revisions/${id}`,
                store,
                "currentSignatoryMatrixRevision"
            );

            return;
        },
        async lock(user, { store, to, db }) {
            if (user?.Security?.Matrices?.Reviewer) return [false];

            const itemLocation = to.meta.dataPathOverride
                ? to.meta.dataPathOverride.replace(":id", to.params.id)
                : to.path
            const item = await db.store(itemLocation, store);

            return [store.getters.userID !== item.AccountID, "Cannot view this area"]
        },
        title(item, { router }) {
            return `Employee: ${item?.FirstName} ${item?.LastName} ${router.currentRoute.value.params.showLatest === "true" ? "<span class='rounded bg-orange text-white p-1 mx-2'>! Unapproved</span>" : "<span class='rounded bg-green text-white p-1 mx-2'>Approved</span>"}`
        },
        "attachedItems": {
            "Additional Details": {
                "attachables": [panels, limitations, files, notes, questions]
            },
            "Form Four Holder Data": {
                "attachables": [formfour]
            },
            "Staff Training and Resposibilties": {
                "attachables": [records]
            }
        },
        "dataFields": [{
            "label": "TSR Number & Name",
            compute({ item }) {
                return `${item.CSR} - ${item.FirstName} ${item.LastName}`;
            }
        },
        {
            "label": "Email",
            "key": "Email"
        },
        {
            "label": "Position Held / Job Title",
            "key": "Position"
        },
        {
            "label": "Approved Functions",
            "class": "md:col-span-3",
            "grow": true,
            compute({ item, store }) {

                // Calculate the total points for all InterviewQuestions
                const totalPoints = Object.values(item.InterviewQuestions || {}).reduce((acc, q) => acc + (parseFloat(q.Points || 0) || 0), 0);

                // Generate the HTML span elements for each approved function
                const spans = Object.values(store.state["Matrices/SignatoryMatrix/ApprovedFunctions"]).map(i => {
                    return span(
                        // Check if the function is approved for this item
                        item?.ApprovedFunctions ? !!item.ApprovedFunctions[i.ID] : false,
                        totalPoints,
                        // Parse the threshold as an integer or default to 0
                        parseInt(i.Threshold) || 0,
                        `${i.Name}${i.FormFourFunction ? " (Post Holder)" : ""}`,
                        item
                    );
                }).sort();

                // Return the final HTML string with the generated spans inside a grid
                return `
                    <div class="grid grid-cols-1 md:grid-cols-3">
                    ${spans.join("")}
                    </div>
                `;
            }
        }],
        "menuActions": [{
            "label": "Update Personal Data",
            "action": updateEmployee,
            show({ store }) {
                if (store?.state?.sigMatrixViewingNew === false) {
                    return [false, "You cannot update this when viewing a revision"];
                } else {
                    return [store?.state?.user?.Security?.Matrices?.Reviewer === true, "You cannot update this"]
                }
            }
        }, {
            "label": "Update Approved Functions",
            "action": updateApprovedFunctions,
            show({ store }) {
                if (store?.state?.sigMatrixViewingNew === false) {
                    return [false, "You cannot update this when viewing a revision"];
                } else {
                    return [store?.state?.user?.Security?.Matrices?.Reviewer === true, "You cannot update this"]
                };
            }
        }, {
            "label": "Make Active",
            "action": makeActive,
            "break": true,
            show({ pageItem }) { return [pageItem.Cancelled === true, "This signatory is already active"] }
        }, {
            "label": "Make Inactive",
            "action": makeInactive,
            show({ pageItem }) { return [pageItem.Cancelled !== true, "This signatory is already inactive"] }

        }]
    }
}, {
    "path": "/Matrices/SignatoryMatrix/NewSignatories/:id",
    "component": ItemView,
    "name": "NewSignatory",
    "meta": {
        "app": "Matrices",
        "name": "NewSignatory",
        "dataPathOverride": "OpenForms/Signatories/:id",
        "storeData": [
            "Matrices/SignatoryMatrix/InterviewQuestions",
            "Matrices/SignatoryMatrix/Panels",
            "Matrices/SignatoryMatrix/ApprovedFunctions",
            "Matrices/SignatoryMatrix/Helper"
        ],
        title(item) {
            return `Employee: ${item?.FirstName} ${item?.LastName}`
        },
        "attachedItems": {
            "Additional Details": {
                "attachables": [questions2, files2]
            }
        },
        "dataFields": [{
            "label": "Name",
            compute({ item }) {
                return `${item.FirstName} ${item.LastName}`;
            }
        },
        {
            "label": "Email",
            "key": "Email"
        },
        {
            "label": "Role",
            compute({ pageItem, store }) {
                return store.state["Matrices/SignatoryMatrix/ApprovedFunctions"][pageItem.Role].Name
            }
        }],
        "menuActions": [{
            "label": "Approve Signatory Application",
            "action": approveSignatoryApplication,
            show({ store }) {

                return [store.state.user?.Security?.Matrices?.Reviewer === true, "Must be a HoD to approve this application"];
            }
        }],
        lock(user) {
            return [user?.Security?.Matrices?.Reviewer !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Matrices/SignatoryMatrix/Testers",
    "component": { ...ItemListView },
    "name": "Test Witnesses",
    "meta": {
        "app": "Matrices",
        "sideNav": true,
        "icon": "clipboard-list",
        "name": "Test Witnesses",
        "sideNavSection": "Testers",
        "tableSettings": "Testers",
        "selectionRoute": "Matrices/SignatoryMatrix/Testers/{ID}",
        "dataPath": "Matrices/SignatoryMatrix/Testers",
        "menuActions": [{
            "label": "Add New Tester",
            "action": addTester,
        }],
        lock(user) {
            return [user?.Security?.Matrices?.Reviewer !== true, "Cannot view this area"]
        }

    },
    lock(user) {
        return [user?.Security?.Matrices?.Reviewer !== true, "Cannot view this area"]
    }
}, {
    "path": "/Matrices/SignatoryMatrix/Testers/:id",
    "component": { ...ItemView },
    "name": "Test Witness",
    "meta": {
        "sideNav": false,
        "app": "Matrices",
        "name": "Test Witness",
        "title": () => "Tester",
        "dataPath": "Matrices/SignatoryMatrix/Testers",
        "dataFields": [{
            "label": "Name",
            "key": "Name"
        }, {
            "label": "Types",
            "key": "Types",
        }],
        "menuActions": [{
            "label": "Edit Tester",
            "action": editTester,
        }],
        "attachedItems": {
            "Additional Details": {
                "attachables": [testersFiles]
            }
        }
    }
}, ...settings]
