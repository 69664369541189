import {
    addForm,
    cancelForm,
    reviseForm,
    updateForm
} from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import { get } from "lodash";
import revisions from "./internalAttachable";
import { standardDateLocal } from "../../../utils";

const dataFields = [{
    "label": "Form Number",
    "key": "FormNumber"
}, {
    "label": "Name",
    "key": "NameShort"
}, {
    "label": "Revision",
    "key": "Revision"
}, {
    "label": "Last Update",
    compute({ pageItem }) {
        
        const data = get(pageItem, `Revisions.${pageItem.RevisionID}.Date`, null);
        if(!data) return "No Date";
        return (new Date(pageItem.Revisions[pageItem.RevisionID].Date)).toLocaleDateString("en-gb", standardDateLocal);
    }
}];

const menuActions = [
    {
        "label": "Update Form Template",
        "action": updateForm
    },
    {
        "label": "Revise Form Template",
        "action": reviseForm
    },
    {
        "label": "Download Latest",
        async action({ db,  pageItem }) {
            let rev = pageItem.Revisions[pageItem.RevisionID];
            let url = await db.download(`Database/DocumentTypes/${pageItem.ID}/Revisions/${pageItem.RevisionID}/${rev.File}`);
            window.open(url, "__blank");
        }
    },
    {
        "label": "Cancel Form Template",
        "action": cancelForm,
        "break": true,
        "class": "font-bold text-red"
    }
];

export default [{
    "path": "/Home/Forms",
    "component": {...ItemListView},
    "name": "Form Templates",
    "meta": {
        "app": "Home",
        "name": "Form Templates",
        "sideNav": true,
        "sideNavSection": "Forms, Handbooks & Work Instructions",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Forms",
        "dataPath": "Database/DocumentTypes",
        "dataPathOverride": "Database/DocumentTypes/:id",
        "selectionRoute": "Home/Forms/{ID}",
        "menuActions": [{
            "label": "Add Form Template",
            "action": addForm
        }],
        lock(user) {
            return [user?.Security?.Database?.Read !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Home/Forms/:id",
    "component": ItemView,
    "name": "Form",
    "meta": {
        "app": "Home",
        "name": "Forms",
        "dataPath": "Database/DocumentTypes",
        "dataPathOverride": "Database/DocumentTypes/:id",
        "selectionRoute": "Home/Forms/{ID}",
        title(item) {
            return `Forms: ${item.NameShort}`
        },
        "attachedItems": {
            "Revisions": {
                "attachables": [revisions]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions
    }
}];
