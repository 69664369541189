import {
    next
} from "../../../utils.js";

export const addForm = ({ form, pageItem, store, attachedItems }) => {
    form.setConfig({
        "title": "Add Form Type",
        "type": "push",
        "location": "Database/DocumentTypes",
        "fields": [{
            "label": "Form Number",
            "name": "FormNumber",
            "type": "text"
        },{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Acronym",
            "name": "NameShort",
            "type": "text"
        }],
        preSubmitHook() {
            return {
                "Count": 0,
                "DesignChanges": false,
                "Documents": false,
                "ExpectedTime": "0",
                "FlightConditions": false,
                "Repairs": false,
                "Revision": 0,
                "RevisionID": "---0",
                "Revisions": {
                    "---0": {
                        "By": "N/A",
                        "Date": (new Date()).toISOString(),
                        "ID": "---0",
                        "Reason": "Initial Draft",
                        "Revision": 0
                    }
                },
                "SignedoffRequired": false,
                "Standard": {
                    "DesignChanges": false,
                    "FlightConditions": false,
                    "Repair": false
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const updateForm = ({ form, pageItem }) => {

    form.setValues({
        "FormNumber": pageItem.FormNumber
    });

    form.setConfig({
        "title": "Update Form Type",
        "type": "update",
        "location": `Database/DocumentTypes/${pageItem.ID}`,
        "fields": [{
            "label": "Form Number",
            "name": "FormNumber",
            "type": "text"
        }],
    });

    form.toggle();

};

export const reviseForm = async ({ form, pageItem, store, db }) => {

    // Calculate the next revision
    let nextRevision = next(pageItem.Revision);
    if(nextRevision === undefined) nextRevision = 1;
    let nextRevisionID = `--R${nextRevision}`;

    const mfarQuestions = Object.values(await db.store("Database/MFARQuestions", store));

    const mfarFields = mfarQuestions.map((question) => {
        return {
            "label": question.Question,
            "name": `Revisions[${nextRevisionID}][MFAR][${question.ID}]`,
            "type": "dropdown",
            "dropdownData": [{
                "label": "Yes",
                "id": "Yes"
            }, {
                "label": "N/A",
                "id": "N/A"
            }]
        };
    });

    form.setConfig({
        "title": "Revise Form",
        "description": `Revise Form <strong class="text-primary">${pageItem.Name}</strong> from Revision <strong class="text-primary">${pageItem.Revision || "N/A"}</strong> to Revision <strong class="text-primary">${nextRevision}</strong>.<br />`,
        "location": `Database/DocumentTypes/${pageItem.ID}`,
        "type": "update",
        "fields": [{
            "label": "New Form Template File",
            "name": `Revisions[${nextRevisionID}][File]`,
            "type": "file"
        }, {
            "label": "Sign off Date",
            "name": `Revisions[${nextRevisionID}][Date]`,
            "type": "date"
        }, {
            "label": "Reason",
            "name": `Revisions[${nextRevisionID}][Reason]`,
            "type": "text",
            "divClass": "md:col-span-3"
        }, {
            "label": "MFAR Questions",
            "type": "sectionHeader",
            "divClass": "md:col-span-3"
        }, ...mfarFields],
        async preSubmitHook({ data, files, db }) {
            for (const file of files) {
                await db.upload(`Database/DocumentTypes/${pageItem.ID}/Revisions/${nextRevisionID}`, file[0]);
            }
            await db.set(`Database/DocumentTypes/${pageItem.ID}/Revisions/${nextRevisionID}/File`, files[0][0].name);
            // await db.set(`Database/DocumentTypes/${pageItem.ID}/Revisions/${nextRevisionID}/MFAR`, files[1][0].name);

            return {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                [`Revisions/${nextRevisionID}`]: {
                    "Revision": nextRevision,
                    "By": store.state.user.Basic.name,
                    "ID": nextRevisionID
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const updateFormRevision = ({ form, rowItem, pageItem }) => {
    form.setValues({
        "Date": rowItem.Date,
        "Reason": rowItem.Reason,
        "Number": rowItem.Number
    });
    form.setConfig({
        "title": "Update Revision",
        "type": "update",
        "location": `Database/DocumentTypes/${pageItem.ID}/Revisions/${rowItem.ID}`,
        "fields": [{
            "label": "Sign off Date",
            "name": "Date",
            "type": "date"
        }, {
            "label": "File",
            "name": `Revisions[${rowItem.ID}][File]`,
            "type": "file",
            "required": false
        }, {
            "label": "Database Generatable Form File",
            "name": `Revisions[${rowItem.ID}][GeneratableFile]`,
            "type": "file",
            "required": false
        },{
            "label": "Reason",
            "name": "Reason",
            "type": "text",
            "divClass": "md:col-span-3"
        }],
        async preSubmitHook({ data, newData, files, db }) {
            if (files[0].length > 0) {
                await db.upload(`Database/DocumentTypes/${pageItem.ID}/Revisions/${rowItem.ID}`, files[0][0]);
                await db.set(`Database/DocumentTypes/${pageItem.ID}/Revisions/${rowItem.ID}/File`, files[0][0].name);
            };
            if(files[1].length > 0) {
                await db.upload(`Database/DocumentTypes/${pageItem.ID}/Revisions/${rowItem.ID}`, files[1][0]);
                await db.set(`Database/DocumentTypes/${pageItem.ID}/Revisions/${rowItem.ID}/GeneratableFile`, files[1][0].name);
            };
        }
    });
    form.toggle();
};

export const viewFormRevision = async ({ form, rowItem, db, store }) => {

    const mfarQuestions = Object.values(await db.store("Database/MFARQuestions", store));

    const mfarFields = mfarQuestions.map((question) => {
        return {
            "label": question.Question,
            "name": question.ID,
            "type": "static-value"
        };
    });

    const mfarValueNames = mfarQuestions.map((question) => {
        return question.ID;
    });

    form.setValues({
        "Date": rowItem.Date,
        "Reason": rowItem.Reason,
        "Number": rowItem.Number,
        ...(
            Object.fromEntries(
                mfarValueNames.map(
                    (name) => ([name, rowItem.MFAR[name]])
                )
            )
        )
    });

    form.setConfig({
        "title": `View Revision ${rowItem.Revision}`,
        "type": "view",
        "fields": [{
            "label": "Sign off Date",
            "name": "Date",
            "type": "static-value"
        }, {
            "label": "Reason",
            "name": "Reason",
            "type": "static-value",
            "divClass": "md:col-span-3"
        }, {
            "label": "MFAR Questions",
            "type": "sectionHeader",
            "divClass": "md:col-span-3"
        }, ...mfarFields]
    });
    form.toggle();
};

export const cancelForm = ({ pageItem, form }) => {
    form.setConfig({
        "title": "Cancel Form",
        "type": "update",
        "location": `Database/DocumentTypes/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true
            };
        }
    });
    form.toggle();
}
