import {
    appConfig,
    makeAttached,
    makeDropdown,
    pad
} from "../../../utils.js";

export const addServiceBulletin = ({ form, pageItem, store, attachedItems, meta }) => {

    form.setConfig({
        "title": "Add Service Bulletin",
        "type": "push",
        "location": "Database/ServiceBulletins",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}SB-${pad(store.state.counts.database.service_bulletin.count)}`
            }
        }, {
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-2"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems?.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Cancelled": "N/A",
                    "Signedoff": "N/A"
                },
                "Cancelled": false,
                "Signedoff": false,
                "Author": store.getters.userName,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "Parents": {
                    [pageItem.ID]: true
                },
                "ParentType": meta.ParentType,
                "Number": `${appConfig.docPrefix}SB-${pad(store.state.counts.database.service_bulletin.count)}`,
                "ServiceBulletins": makeAttached(data.ServiceBulletins),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities),
                "Revision": 1,
                "RevisionID": "--R1",
                "Revisions": {
                    "--R1": {
                        "Revision": 1,
                        "Date": (new Date()).toISOString(),
                        "Reason": "Service Bulletin Created",
                        "ID": "--R1"
                    }
                }
            }
        },
        async postSubmitHook({ db, newData }) {

            await db.utils.upCount("counts/database/service_bulletin/count");
            await db.set(`Database/${meta.ParentType}/${pageItem.ID}/ServiceBulletins/${newData.ID}`, true);

            for (let doc of Object.keys(newData.Documents || {})) {
                await db.set(`Database/Documents/${doc}/ServiceBulletins/${newData.ID}`, true);
            }

            for (let drawing of Object.keys(newData.Drawings || {})) {
                await db.set(`Database/Drawings/${drawing}/ServiceBulletins/${newData.ID}`, true);
            }

            for (let effectivity of Object.keys(newData.Effectivities || {})) {
                await db.set(`Database/Effectivities/${effectivity}/ServiceBulletins/${newData.ID}`, true);
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editServiceBulletin = async ({ form, item, attachedItems, store }) => {
    form.setValues({
        "Comment": item.Comment,
        "ServiceBulletins": Object.keys(item.Documents || {}),
        "Drawings": Object.keys(item.Drawings || {}),
        "Effectivities": Object.keys(item.Effectivities || {})
    });
    form.setConfig({
        "title": "Update Service Bulletin",
        "type": "update",
        "location": `Database/ServiceBulletins/${item.ID}`,
        "set": true,
        "fields": [{
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities)
            }
        },
        async postSubmitHook({ db, newData }) {
            for (let doc of Object.keys(newData.Documents || {})) {
                await db.set(`Database/Documents/${doc}/ServiceBulletins/${newData.ID}`, true);
            }

            for (let drawing of Object.keys(newData.Drawings || {})) {
                await db.set(`Database/Drawings/${drawing}/ServiceBulletins/${newData.ID}`, true);
            }

            for (let effectivity of Object.keys(newData.Effectivities || {})) {
                await db.set(`Database/Effectivities/${effectivity}/ServiceBulletins/${newData.ID}`, true);
            }
        }
    });
    await form.getDropdownData();

    form.toggle();
};

export const cancelServiceBulletin = ({ form, item }) => {
    form.setConfig({
        "title": "Cancel ServiceBulletin",
        "description": `Are you sure you want to cancel ServiceBulletin ${item.Number}?`,
        "type": "update",
        "location": `Database/ServiceBulletins/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date/Cancelled": new Date().toISOString()
            }
        }
    });
    form.toggle();
};

export const signoffServiceBulletin = ({ form, item }) => {
    form.setConfig({
        "title": "Signoff Service Bulletin",
        "description": `Are you sure you want to signoff Service Bulletin ${item.Number}?`,
        "type": "update",
        "location": `Database/ServiceBulletins/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Signedoff": true,
                "Date": {
                    "Signedoff": new Date().toISOString()
                }
            }
        }
    });
    form.toggle();
};

export const linkServiceBulletin = ({ form, pageItem, meta }) => {
    form.setConfig({
        "title": "Link ServiceBulletin",
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}`,
        "fields": [{
            "label": "ServiceBulletin",
            "name": "ServiceBulletin",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Database/ServiceBulletins"
        }],
        preSubmitHook({ liveInputData }) {
            return {
                "ServiceBulletin": null,
                [`ServiceBulletins/${liveInputData.ServiceBulletin}`]: true
            }
        },
        async postSubmitHook({ db, newData, liveInputData }) {

            await db.set(`Database/ServiceBulletins/${liveInputData.ServiceBulletin}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();

    form.toggle();
};

export const unlinkServiceBulletin = ({ form, pageItem, rowItem, meta }) => {
    form.setConfig({
        "title": "Unlink ServiceBulletin",
        "type": "delete",
        "desctiption": `Are you sure you want to unlink ServiceBulletin ${rowItem.Number}?`,
        "location": `Database/${meta.ParentType}/${pageItem.ID}/ServiceBulletins/${rowItem.ID}`,
        "fields": [],

        postSubmitHook({ db, liveInputData }) {
            db.set(`Database/ServiceBulletins/${rowItem.ID}/${meta.ParentType}/${pageItem.ID}`, null);
        }
    });
    form.getDropdownData();

    form.toggle();
}

export const reviseServiceBulletin = ({ form, pageItem, item, store }) => {

    // Calculate the next revision
    let nextRevision = item.Revision.toString().includes("P") ? `P${parseInt(item.Revision.split("P")[1]) + 1}` : parseInt(item.Revision) + 1;
    let nextRevisionID = `--R${nextRevision}`;
    form.setItem(item);
    form.setConfig({
        "title": "Revise Service Bulletin",
        "description": `Revise Service Bulletin <strong class="text-primary">${item.Number}</strong> from Revision <strong class="text-primary">${item.Revision}</strong> to Revision <strong class="text-primary">${nextRevision}</strong>.`,
        "location": `Database/ServiceBulletins/${item.ID}`,
        "type": "update",
        "fields": [{ 
            "label": "Reason",
            "name": `Revisions/${nextRevisionID}/Reason`,
            "type": "text",
            "divClass": "md:cols-span-3"
        }],
        preSubmitHook({ data }) {
            return {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                "Signedoff": false,
                "Revisions": {
                    [nextRevisionID]: {
                        "Date": (new Date()).toISOString(),
                        "Revision": nextRevision,
                        "RevisedBy": store.state.user.Basic.name,
                        "ID": nextRevisionID
                    }
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};
