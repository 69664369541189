import {
    appConfig,
    next,
    pad
} from "../../../utils";

import { Convert } from "easy-currencies";

export const addServiceOrder = ({ pageItem, form, store, db }) => {
    form.setValues({
        "Price": pageItem.Quote.Price
    });
    form.setConfig({
        "title": "Add Service Order",
        "description": `Add a Service Order to Commercial ${pageItem.Number}`,
        "type": "push",
        "location": "Database/ServiceOrders",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}SO-${pad(store.state.counts.database.serviceOrders.count)}`
            }
        }, {
            "label": "Price",
            "name": "Price",
            "type": "number"
        }, {
            "label": "Currency",
            "name": "Currency",
            "type": "dropdown",
            "dropdownData": [{
                "label": "£ GBP",
                "id": "gbp"
            }, {
                "label": "$ USD",
                "id": "usd"
            }, {
                "label": "€ EUR",
                "id": "eur"
            }]
        }, {
            "label": "Supplier",
            "name": "Supplier",
            "type": "dropdown",
            "labelKey": "Name",
            "sortKey": "meta.Name",
            "idKey": "Name",
            "dataLocation": "Database/Suppliers"
        }, {
            "label": "Supplier Reference",
            "name": "SupplierReference",
            "type": "text",
            "required": false
        }],
        async preSubmitHook({ data }) {
            let rate = data.Price;
            if (data.Currency !== "gbp") {
                let response = await Convert(data.Price).from(data.Currency.toUpperCase()).to("GBP");

                rate = Math.round(response * 100) / 100;
            }
            return {
                "PriceGBP": rate,
                "CommercialID": pageItem.ID,
                "Commercial": pageItem.Number,
                "Customer": pageItem.Customer,
                "Number": `${appConfig.docPrefix}SO-${pad(store.state.counts.database.serviceOrders.count)}`,
                "PercentagePaid": 0,
                "Time": pageItem.Quote.Time,
                "Date": {
                    "Created": (new Date()).toISOString()
                }
            }
        },
        async postSubmitHook({ data, typeRef }) {
            await db.set("counts/database/serviceOrders/count", next(store.state.counts.database.serviceOrders.count));
            await db.set(`Database/Commercials/${data.CommercialID}/ServiceOrders/${typeRef.key}`, true);

        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editServiceOrder = ({ item, form, db }) => {
    form.setValues({ ...item });
    form.setConfig({
        "title": "Edit Service Order",
        "type": "update",
        "location": `Database/ServiceOrders/${item.ID}`,
        "fields": [{
            "label": "Price",
            "name": "Price",
            "type": "text"
        }, {
            "label": "Currency",
            "name": "Currency",
            "type": "dropdown",
            "dropdownData": [{
                "label": "£ GBP",
                "id": "gbp"
            }, {
                "label": "$ USD",
                "id": "usd"
            }, {
                "label": "€ EUR",
                "id": "eur"
            }]
        }, {
            "label": "Supplier",
            "name": "Supplier",
            "type": "dropdown",
            "labelKey": "Name",
            "sortKey": "meta.Name",
            "idKey": "Name",
            "dataLocation": "Database/Suppliers"
        }, {
            "label": "Supplier Reference",
            "name": "SupplierReference",
            "type": "text",
            "required": false
        }],
        async preSubmitHook({ data }) {
            let rate = data.Price;
            if (data.Currency !== "gbp") {
                let response = await Convert(data.Price).from(data.Currency.toUpperCase()).to("GBP");

                rate = Math.round(response * 100) / 100;
            }
            return {
                "PriceGBP": rate,
                "Date": {
                    "Edited": new Date()
                }
            }
        }
    });
    form.toggle();
};


export const addFile = ({ pageItem, form, store, db }) => {
    form.setConfig({
        "title": "Add File",
        "description": `Add a File to Service Order ${pageItem.Number}`,
        "type": "push",
        "location": `Database/ServiceOrders/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "Name": files[0][0].name,
            }
        },
        postSubmitHook({ files, typeRef }) {
            db.upload(`Database/ServiceOrders/${pageItem.ID}/Files/${typeRef.key}`, files[0][0]);

        }
    });

    form.toggle();
};

export const removeFile = ({ pageItem, rowItem, store, db, form }) => {
    form.setConfig({
        "title": "Remove File",
        "description": `Remove File ${rowItem.Name}`,
        "type": "delete",
        "location": `Database/ServiceOrders/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        postSubmitHook() {
            db.remove(`Database/ServiceOrders/${pageItem.ID}/Files/${rowItem.ID}`, rowItem.Name);
        }
    });
    form.toggle();
};
